var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.tableOptions,"server-items-length":_vm.serverItemsLength,"item-key":"id","loading":_vm.isLoading,"loading-text":"Loading...."},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.view},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.shortId))])]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.keyImageMediaItemId)?_c('div',{staticClass:"text-center image-container py-1",staticStyle:{"height":"100px"}},[_c('media-thumbnail',{attrs:{"value":{ id: item.keyImageMediaItemId },"height":100,"max-height":"100px","max-width":"125px"}})],1):_c('div',{staticClass:"text-left image-container py-1"},[_c('v-img',{attrs:{"src":require("@/assets/avatar.png"),"height":"100","max-height":"100px","max-width":"125px","contain":""}})],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.description,150, '...'))+" ")])]}},{key:"item.collections",fn:function(ref){
var item = ref.item;
return _vm._l((item.collections.filter(
        function (x) { return !x.isDeleted & !x.isArchived; }
      )),function(c){return _c('div',{key:c.id,staticClass:"text-left text-no-wrap ",attrs:{"to":{ name: 'collection-detail', params: { collectionId: c.id } }}},[_vm._v(" "+_vm._s(c.collectionShortId)+" - "+_vm._s(c.collectionNumber)+" ")])})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }