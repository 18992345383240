<template>
      <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="tableOptions"
      :server-items-length="serverItemsLength"
      class="elevation-1 row-pointer"
      item-key="id"
      :loading="isLoading"
      loading-text="Loading...."
      @click:row="view"
    >
      <template v-slot:item.id="{ item }">
        <div class="text-no-wrap">{{ item.shortId }}</div>
      </template>

      <template v-slot:item.image="{ item }">
        <div class="text-center image-container py-1" v-if="item.keyImageMediaItemId" style="height:100px;">
          <media-thumbnail
            :value="{ id: item.keyImageMediaItemId }"
            :height="100"
            max-height="100px"
            max-width="125px"
          />
        </div>
        <div v-else class="text-left image-container py-1">
          <v-img
            src="@/assets/avatar.png"
            height="100"
            max-height="100px"
            max-width="125px"
            contain
          ></v-img>
        </div>
      </template>

      <template v-slot:item.description="{ item }">
        <div class="text-left">
          {{ item.description | truncate(150, '...') }}
        </div>
      </template>

      <template v-slot:item.collections="{ item }">
        <div
          class="text-left text-no-wrap "
          v-for="c in item.collections.filter(
            x => !x.isDeleted & !x.isArchived
          )"
          :key="c.id"
          :to="{ name: 'collection-detail', params: { collectionId: c.id } }"
        >
          {{ c.collectionShortId }} - {{ c.collectionNumber }}
        </div>
      </template>

      <!-- <template v-slot:item.actions="{ item }">
        <div class="text-right text-no-wrap">
          {{ item.numberOfMedia }}
          <v-icon small class="mr-2" :title="$t('media')"
            >mdi-folder-multiple-image</v-icon
          >
        </div>
      </template> -->
    </v-data-table>
  </template>
  <script>

  import mediaThumbnail from '@/components/media/media-image'

  export default {
    components: { mediaThumbnail },
    props: {
      items: {
        type: Array,
        required: true
      },
      meta: {
        type: Object,
        required: false
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
        tableOptions: {
          footerProps: {
           showItemsPerPage: false
          },
          page: 1,
          itemsPerPage: 25,
          sortBy: ['createdDateTime'],
          sortDesc: [true]
        },
      }
    },
    computed:{
      headers() {
        return [
          { text: '', value: 'image', sortable: false, width: '125px'},
          { text: this.$t('id'), value: 'shortId', width: '75px' },
          { text: this.$t('name'), value: 'name' },
          // { text: this.$t('description'), value: 'description' },
          { text: this.$t('collections'), value: 'collections', sortable: false },
          { text: '', value: 'actions', sortable: false }
        ]
      },
      paging() {
        if (this.meta) {
          return this.meta.paging
        }
        return {}
      },
      serverItemsLength() {
        return this.meta?.paging?.totalItems
      }
    },
    methods: {
      view(item) {
        this.$emit('view', item)
      }
    }
  }
  </script>
<style lang="scss" scoped>
  ::v-deep .v-data-table tbody tr:hover {
    cursor: pointer;
  }

  ::v-deep .v-data-footer__select {
    display: none;
  }

  .row-pointer > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
</style>
