<template>
  <div>
    <div class="d-flex justify-space-between mb-2">
      <div>
        <h2>{{ $t('people') }}</h2>
      </div>
      <v-card tile flat>
        <v-btn plain :to="{ name: 'person-create' }">
          {{ $t('create') }}
        </v-btn>
      </v-card>
    </div>

    <div class="d-flex justify-start">
      <v-card tile flat :width="$vuetify.breakpoint.mdAndDown ? '100%' : '50%'">
        <v-form @submit.prevent="filter">
          <v-app-bar flat color="transparent" dense>
            <v-text-field
                class="my-3"
                v-model="filterTerm"
                :label="$t('filter')"
                clearable
                clear-icon="mdi-close mdi-sm"
                color="default"
                hide-details="true"
                dense
                solo
                @click:clear="clear"
              >
                <template v-slot:prepend-inner>
                  <v-icon :title="$t('searchesCommon')" small>
                    mdi-text-search
                  </v-icon>
                </template>
              </v-text-field>
            <v-btn
              icon
              @click="layout = 'list'"
            >
              <v-icon>mdi-view-list</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="layout = 'card'"
            >
              <v-icon>mdi-view-grid</v-icon>
            </v-btn>
          </v-app-bar>
        </v-form>
      </v-card>
    </div>

    <list-view v-if="layout == 'list'" :items="items" @view="view" :meta="meta"/>
    <card-view v-if="layout == 'card'"  :items="items" @view="view" :meta="meta"/>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ListView from './list-view'
import CardView from './card-view'
import { PageSize } from '@/constants'

export default {
  components: {
    CardView,
    ListView
  },
  data() {
    return {
      advancedFilters: [],
      filterTerm: '',
      isLoading: true,
      page: 1,
      pageSize: PageSize,
      searchOptions: {},
      selectedRows: [],
      showFilters: false,
      layout: 'list'
    }
  },
  watch: {
    'meta.paging.currentPage': function(val){
      this.page = val
      this.filter()
    },
    tableOptions: {
      handler(val) {
        this.searchOptions = {
          ...this.searchOptions,
          ...{
            page: val.page,
            orderBy: val.sortBy[0],
            isAscending: !val.sortDesc[0]
          }
        }
        this.filter()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('people', {
      items: 'results',
      meta: 'meta',
      term: 'term'
    }),

  },
  async mounted() {
    if (this.$route.query.term) {
      this.filterTerm = this.$route.query.term
    }

    await this.filter()
    this.filterTerm = this.term

    this.isLoading = false
  },
  methods: {
    ...mapActions('people', ['all', 'search', 'setTerm']),
    async clear() {
      this.selectedRows = []

      if (this.term) {
        const options = {}
        await this.all(options)
        this.setTerm({ term: this.filterTerm })
      }
    },
    async filter() {
      this.searchOptions.term = this.filterTerm
      this.selectedRows = []

      this.setTerm(this.searchOptions)

      const options = {
        ...this.searchOptions,
        ...{
          page: this.page,
          pageSize: this.pageSize
        }
      }
      if (this.searchOptions.term) {
        await this.search(options)
      } else {
        await this.all(options)
      }
    },
    view(item) {
      this.$router.push({
        name: 'person-detail',
        params: { personId: item.id }
      })
    }
  }
}
</script>

